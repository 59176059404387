
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { TextArray, ButtonColor } from '@/definitions/shared/types'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'

export default defineComponent({
  components: {
    TmStylizedText,
    TmModal,
    TmButton,
  },
  props: {
    label: {
      type: String,
    },
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
      required: true,
    },
    textContainer: {
      type: String,
      default: 'span',
    },
    btnText: {
      type: String,
      default: 'Save',
    },
    btnColor: {
      type: String as PropType<ButtonColor>,
      default: 'blue',
    },
    hideCancelButton: {
      type: Boolean,
    },
  },
  setup() {
    const val = ref(false)

    return {
      val,
    }
  },
})
