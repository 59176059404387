import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22d08c0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-stylized-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (typeof _ctx.text === 'string')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ], 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.text, (item, index) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent((typeof item !== 'string' && item.link) ? 'router-link' : _ctx.textContainer), {
            key: index,
            class: _normalizeClass([
          (typeof item !== 'string') ? _ctx.weightMap[item.style] : '',
          (typeof item !== 'string') ? _ctx.colorMap[item.color] : '',
        ]),
            to: item.link
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString((typeof item !== 'string') ? item.text : item), 1)
            ]),
            _: 2
          }, 1032, ["class", "to"]))
        }), 128))
  ]))
}