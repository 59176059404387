import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "singleCheckbox",
    size: "small",
    "hide-cancel-button": _ctx.hideCancelButton
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_tm_stylized_text, {
        class: "body-text-regular-14 lh-20 mb-3",
        "text-container": _ctx.textContainer,
        text: _ctx.text
      }, null, 8, ["text-container", "text"]),
      _createVNode(_component_tm_field, {
        modelValue: _ctx.val,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
        type: "checkbox"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: _ctx.btnColor
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    _: 1
  }, 8, ["hide-cancel-button"]))
}