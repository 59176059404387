
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { TextArray, TextWeight, TextColor } from '@/definitions/shared/types'

export const convertArrayToText = (input: TextArray | TextArray[]): TextArray => {
  if (Array.isArray(input)) {
    return input.map(item => typeof item === 'string' ? item : item.text).join('')
  } else {
    return input
  }
}

export default defineComponent({
  name: 'TmStylizedText',
  props: {
    textContainer: {
      type: String as PropType<'span' | 'div'>,
      default: 'span',
    },
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
    },
  },
  setup() {
    const weightMap: Record<TextWeight, string> = {
      medium: 'font-weight-medium',
      'semi-bold': 'font-weight-semi-bold',
      bold: 'font-weight-bold',
    }

    const colorMap: Record<TextColor, string> = {
      green: 'success--text',
      red: 'red--text',
    }

    return { weightMap, colorMap }
  },
})
